import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';

import OSGImage from '../../assets/images/publication-banners/sports-guide.jpg';

const OutdoorSportsGuide = (props) => (
  <Layout>
    <Helmet>
      <title>Outdoor Sports Guide Magazine - Mills Publishing Inc.</title>
      <meta
        name="description"
        content="Advertising and magazine information for Outdoor Sports Guide Magazine. Please contact Mills Publishing for availability and rates."
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Outdoor Sports Guide Magazine</h1>
          </header>
          <p>
            Endurance sports and outdoor recreation collide in Outdoor Sports
            Guide, the best free publication for active individuals in Utah and
            the Mountain West. Published since 1982, each issue is packed with
            how-to articles, athlete profiles, adventure travel writing, and
            gear reviews, along with a current event and race calendar.
          </p>
          <ul className="actions">
            <li>
              <a href="/advertising-specifications" className="button special">
                Outdoor Sports Guide Ad Specs
              </a>
            </li>
          </ul>
          <span className="image main">
            <img src={OSGImage} alt="" />
          </span>
          <div className="inner">
            <header className="major">
              <h2>Activate Your Business with Active People</h2>
            </header>
            <p>
              Every issue of the Outdoor Sports Guide boasts an average
              readership of 50,000, while our website (which also offers digital
              advertising) reaches an additional average of 10,000 views every
              time. With six issues every year—one of which is our annual
              Keeper, which reaches runners, bikers, and other competitive
              athletes—your ad is sure to be seen by dedicated fans of outdoor
              activities, including biking, hiking, skiing, canoeing, and more!
            </p>
          </div>
          <ul className="actions">
            <li>
              <a href="https://sportsguidemag.com/" className="button special">
                <i className="icon fa-globe"> </i>Outdoor Sports Guide Website
              </a>
            </li>
          </ul>
          <p className="box">
            At Outdoor Sports Guide®, we live for being outside. Our content is
            fueled by this passion and partnered with a love for endurance
            sports and racing. We inspire our readers to get outdoors while also
            training and racing their best. Our FREE magazine and website cover
            adventurous destinations throughout the Mountain West with special
            features on locations around the country.
            <br />
            <br />
            Published six time a year, each issue of Outdoor Sports Guide® is
            packed with how-to guides, narrative features, training tips, local
            profiles, adventure travel tales, gear reviews, and a current event
            calendar.
            <br />
            <br />
            We share breaking industry news, events, and more current content
            through our website, newsletter, and social media. These channels
            increase advertiser exposure and enhance our visibility in today’s
            market.
            <br />
            <br />
            Outdoor Sports Guide® is available FREE in northern Utah wherever
            active people gather: ski resorts, sporting good stores, health
            clubs, gyms, races, coffee shops, and restaurants. A digital version
            of the magazine and e-newsletter subscriptions are available online
            on our <a href="https://sportsguidemag.com">website</a>.
          </p>

          {/* <h2>Outdoor Sports Guide 2020 Information</h2>
          <em>
            IMPORTANT: Due do the current times please remember these dates may
            not be accurate until we learn more.
          </em>
          <div className="box">
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Issue</th>
                    <th>Date</th>
                    <th>Content</th>
                    <th>File Ready</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Summer</td>
                    <td>August/September</td>
                    <td>
                      The BIKE Issue | Backpacking, Trail Running, Aquarius Bike
                      Trail Hut to Hut Travel, Water Recreation (SUP and
                      swimming).
                    </td>
                    <td>July 9, 2020</td>
                  </tr>
                  <tr>
                    <td>Fall</td>
                    <td>October/November</td>
                    <td>
                      Ski Resort Updates, Ski Swaps, Season’s Best Ski Gear,
                      Where to Buy and Sell Used Gear, Fall Color Rides or
                      Hikes, Rock Climbing.
                    </td>
                    <td>September 10, 2020</td>
                  </tr>
                  <tr>
                    <td>Winter</td>
                    <td>December/January</td>
                    <td>
                      Winter Gear + Gift Guide, Ski Resort Holiday Events, Local
                      Athlete Profiles, Family Winter Trips.
                    </td>
                    <td>November 9, 2020</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  </Layout>
);

export default OutdoorSportsGuide;
